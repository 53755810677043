import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UpdateCoffee.css'; // Подключаем файл стилей для компонента UpdateCoffee

function UpdateCoffee() {
  const [coffees, setCoffees] = useState([]);
  const [cafes, setCafes] = useState([]);
  const [selectedCoffee, setSelectedCoffee] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [typeCoffee, setTypeCoffee] = useState('');
  const [volume, setVolume] = useState('');
  const [cafeId, setCafeId] = useState('');
  const [date, setDate] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const coffeeTypes = [
    'ESPRESSO', 'AMERICANO', 'CAPPUCCINO', 'LATTE', 'RAF', 'FLAT_WHITE',
    'MACCHIATO', 'MOCHA', 'COLD_BREW', 'NITRO', 'OTHER', 'NOT_COFFEE'
  ];

  useEffect(() => {
    const fetchCoffees = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/coffees/names`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(username + ':' + password),
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch coffee list');
        }

        const data = await response.json();
        setCoffees(data);
      } catch (error) {
        setError('Error fetching coffee list');
        console.error(error);
      }
    };

    fetchCoffees();
  }, [username, password]);

  useEffect(() => {
    const fetchCafes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/cafes/names`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch cafes list');
        }

        const cafesData = await response.json();
        setCafes(cafesData);
      } catch (error) {
        setError('Error fetching cafes list');
        console.error(error);
      }
    };

    fetchCafes();
  }, []);

  const handleCoffeeSelect = async (e) => {
    const coffeeId = e.target.value;
    setSelectedCoffee(coffeeId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coffees/${coffeeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password),
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch coffee data');
      }

      const data = await response.json();
      setName(data.name);
      setDescription(data.description);
      setPrice(data.price);
      setTypeCoffee(data.type_coffee);
      setVolume(data.volume);
      setCafeId(data.cafe_id);
      setDate(data.date);
    } catch (error) {
      setError('Error fetching coffee data');
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        name,
        description,
        price,
        type_coffee: typeCoffee,
        volume,
        cafe_id: cafeId,
        date: date ? date : null
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/coffees/${selectedCoffee}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password),
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Post updated:', data);

      navigate('/'); // Перенаправление на главную страницу после успешного обновления
    } catch (error) {
      setError('Error updating coffee');
      console.error('There was an error updating the coffee!', error);
    }
  };

  return (
    <div className="updateCoffeeContainer">
      <h2 className="formTitle">Update Coffee</h2>
      {error && <p className="formError">{error}</p>}
      {!selectedCoffee ? (
        <div className="formGroup">
          <label className="formLabel">Select Coffee:</label>
          <select onChange={handleCoffeeSelect} defaultValue="" className="formSelect">
            <option value="" disabled>Select a coffee</option>
            {coffees.map((coffee) => (
              <option key={coffee.id} value={coffee.id}>
                {coffee.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="updateCoffeeForm">
          <div className="formGroup">
            <label className="formLabel">Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="formTextarea"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Price:</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Type of Coffee:</label>
            <select value={typeCoffee} onChange={(e) => setTypeCoffee(e.target.value)} className="formSelect">
              <option value="">Select a type</option>
              {coffeeTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="formGroup">
            <label className="formLabel">Volume:</label>
            <input
              type="number"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Cafe:</label>
            <select value={cafeId} onChange={(e) => setCafeId(e.target.value)} className="formSelect">
              <option value="">Select a cafe</option>
              {cafes.map((cafe) => (
                <option key={cafe.id} value={cafe.id}>
                  {cafe.name}
                </option>
              ))}
            </select>
          </div>
          <div className="formGroup">
            <label className="formLabel">Date:</label>
            <input
              type="number"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="formInput"
            />
          </div>
          <div className="formGroup">
            <label className="formLabel">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="formInput"
            />
          </div>
          <button type="submit" className="formButton">Update Coffee</button>
        </form>
      )}
    </div>
  );
}

export default UpdateCoffee;

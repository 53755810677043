import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddPost.css'; // Подключим отдельный файл стилей для компонента AddPost

function AddPost() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [typeCoffee, setTypeCoffee] = useState('');
  const [volume, setVolume] = useState('');
  const [cafeId, setCafeId] = useState('');
  const [date, setDate] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cafes, setCafes] = useState([]); // Состояние для списка кафе

  const navigate = useNavigate();

  const coffeeTypes = [
    'ESPRESSO', 'AMERICANO', 'CAPPUCCINO', 'LATTE', 'RAF', 'FLAT_WHITE',
    'MACCHIATO', 'MOCHA', 'COLD_BREW', 'NITRO', 'OTHER', 'NOT_COFFEE'
  ];

  // useEffect для запроса на бэк для получения списка кафе
  useEffect(() => {
    const fetchCafes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/cafes/names`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(username + ':' + password) // Добавляем авторизацию, если необходимо
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCafes(data); // Устанавливаем список кафе в состоянии
      } catch (error) {
        console.error('There was an error fetching the cafes!', error);
      }
    };

    fetchCafes();
  }, [username, password]); // Запрашиваем список кафе при изменении username и password

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        name,
        description,
        price,
        type_coffee: typeCoffee,
        volume,
        cafe_id: cafeId,
        date: date ? date : null
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/coffees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(username + ':' + password)
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Post created:', data);
      setName('');
      setDescription('');
      setPrice('');
      setTypeCoffee('');
      setVolume('');
      setCafeId('');
      setDate('');

      // Navigate to the home page after successful post creation
      navigate('/');
    } catch (error) {
      console.error('There was an error creating the post!', error);
    }
  };

  return (
    <div className="addPostContainer">
      <h2 className="formTitle">Add a New Post</h2>
      <form onSubmit={handleSubmit} className="addPostForm">
        <div className="formGroup">
          <label className="formLabel">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="formInput"
          />
        </div>
        <div className="formGroup">
          <label className="formLabel">Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="formTextarea"
          />
        </div>
        <div className="formGroup">
          <label className="formLabel">Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="formInput"
          />
        </div>
        <div className="formGroup">
          <label className="formLabel">Type of Coffee:</label>
          <select value={typeCoffee} onChange={(e) => setTypeCoffee(e.target.value)} className="formSelect">
            <option value="">Select a type</option>
            {coffeeTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="formGroup">
          <label className="formLabel">Volume:</label>
          <input
            type="number"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
            className="formInput"
          />
        </div>
        <div className="formGroup">
          <label className="formLabel">Cafe:</label>
          <select value={cafeId} onChange={(e) => setCafeId(e.target.value)} className="formSelect">
            <option value="">Select a cafe</option>
            {cafes.map((cafe) => (
              <option key={cafe.id} value={cafe.id}>
                {cafe.name}
              </option>
            ))}
          </select>
        </div>
        <div className="formGroup">
          <label className="formLabel">Date:</label>
          <input
            type="number"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="formInput"
          />
        </div>
        <div className="formGroup">
          <label className="formLabel">Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="formInput"
          />
        </div>
        <div className="formGroup">
          <label className="formLabel">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="formInput"
          />
        </div>
        <button type="submit" className="formButton">Add Post</button>
      </form>
    </div>
  );
}

export default AddPost;